export const saveToLocalStorage = (key, value) => {
  try {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
  } catch (error) {
  }
};

export const loadFromLocalStorage = (key) => {
  try {
    const serializedValue = localStorage.getItem(key);
    return serializedValue ? JSON.parse(serializedValue) : null;
  } catch (error) {
    return null;
  }
};

export const removeFromLocalStorage = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
  }
};


export const cleanupLocalStorage = () => {
  try {
    // List of keys that should be kept
    const keysToKeep = [
      'morphr_user_input',
      'morphr_generated_prompts',
      'morphr_number_of_prompts',
      'morphr_exclusions',
      'morphr_prompt_components'
    ];

    const allKeys = Object.keys(localStorage);

    allKeys.forEach(key => {
      if (key.startsWith('morphr_') && !keysToKeep.includes(key)) {
        localStorage.removeItem(key);
      }
    });

  } catch (error) {
  }
};


export const checkAndUpdateLocalStorageVersion = () => {
  const currentVersion = '1.1'; 
  const storedVersion = localStorage.getItem('morphr_storage_version');

  if (storedVersion !== currentVersion) {
    cleanupLocalStorage();
    localStorage.setItem('morphr_storage_version', currentVersion);
  }
};