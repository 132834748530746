const isProduction = process.env.NODE_ENV === 'production';

const logger = {
  log: (...args) => {
    if (!isProduction) {
      if (typeof args[0] === 'string' && args[0].startsWith('%c')) {
        console.log(...args);
      } else {
        console.log(...args);
      }
    }
  },
  info: (...args) => {
    if (!isProduction) {
      console.info(...args);
    }
  },
  warn: (...args) => {
    if (!isProduction) {
      console.warn(...args);
    }
  },
  error: (...args) => {
    if (isProduction) {
      console.error('An error occurred:', ...args);
    } else {
      console.error(...args);
    }
  }
};

module.exports = logger;